














































import { apiCrontabLists, apiCrontabDel, apiSrontabOperate } from "@/api/setting/system_maintain/system_maintain";
import { Component, Prop, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        LsDialog,
        LsPagination
    },
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging();

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiCrontabLists,
                params: {},
            })
            .catch(() => {
                this.$message.error("数据请求失败，刷新重载!");
            });
    }

    onStop(row: any) {
        apiSrontabOperate({
            id: row.id,
            operate: row.status==1?'stop':'start'
        }).then(() => {
            this.getList();
        })
    }

    // 删除这个定时任务
    onDel(id: any) {
        apiCrontabDel({ id: id })
            .then(() => {
                // 删除成功就请求新列表
                this.getList();
                this.$message.success("删除成功!");
            })
            .catch(() => {
                this.$message.error("删除失败!");
            });
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: "/setting/task_edit",
            query: {
                mode: PageMode["ADD"],
            },
        });
    }

    // 编辑
    goTaskEdit(id: any) {
        this.$router.push({
            path: "/setting/task_edit",
            query: {
                id: id,
                mode: PageMode["EDIT"],
            },
        });
    }

    created () {
        this.getList()
    }
}
